import {useIntl} from 'react-intl';
import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {IProtocolSummary, IProtocolThumbResponse, IVehicle} from "../../lib/types/types";
import {ProtocolService} from "../../services/protocolService";
import styled from "styled-components";
import {Divider} from "primereact/divider";
import moment from "moment";
import {UtilService} from "../../services/utilService";
import {saveAs} from "file-saver";
import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {useToast} from "../../lib/hooks/useToast";
import {Button} from "primereact/button";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import {Fieldset} from "primereact/fieldset";
import {ClientFirmService} from "../../services/clientFirmService";
import {GpsProgramService} from "../../services/gpsProgramService";
import {VehicleService} from "../../services/vehicleService";
import {Dialog} from "primereact/dialog";
import {useEventListener} from "primereact/hooks";
import {useWindows} from "../../lib/hooks/useWindows";
import {useHistory} from "react-router-dom";
import {useDialog} from "../../lib/hooks/useDialog";
import {DeviceService} from "../../services/deviceService";
import {PermissionContext} from "../../lib/context/PermissionsContext";
import PdfPreview from "../../lib/templates/PdfPreview";

interface Props {
    protocolId: number
}

const Image = styled.img`
    border-radius: 1rem 1rem 0 0;
    border: 1px solid rgba(173, 173, 173, 0.38)
`

const DownloadButton = styled.div`
    background: #2196f3;
    color: white;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0 0 0 1rem;
    cursor: pointer;
    user-select: none;
    margin-bottom: 2rem;
    width: 80%;
`

const DeleteButton = styled.button<{ disabled: boolean; }>`
    background: ${props => props.disabled ? "#f88282" : "red"};
    color: white;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0 0 1rem 0;
    cursor: pointer;
    user-select: none;
    margin-bottom: 2rem;
    width: 20%;
`

const PreviewProtocol: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showToast} = useToast();
    const {addWindow} = useWindows();
    const fileUploadRed = useRef<any>();
    const {showConfirmDialog} = useConfirmDialog();
    const history = useHistory();
    const {hideDialog} = useDialog();
    const {showDialog, isShown: isSidebarShown} = useDialog();
    const protocolService = new ProtocolService();
    const clientFirmService = new ClientFirmService();
    const gpsProgramService = new GpsProgramService();
    const vehicleService = new VehicleService();
    const deviceService = new DeviceService();
    const {allowAction} = useContext(PermissionContext);

    const [protocolThumbs, setProtocolThumbs] = useState<IProtocolThumbResponse[]>();
    const [protocolSummary, setProtocolSummary] = useState<IProtocolSummary>();
    const [vehicleForProtocol, setVehicleForProtocol] = useState<IVehicle>();
    const [imageSrc, setImageSrc] = useState<any>();
    const [showImageDialog, setShowImageDialog] = useState<boolean>(false);
    const [imageBlobMap, setImageBlobMap] = useState<{ [key: number]: Blob }>();
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>();
    const selectedPhotoIndexRef = useRef<number>();
    const [showPdfDialog, setShowPdfDialog] = useState(false);
    const [pdfUrl, setPdfUrl] = useState<string>();

    const [bindKeyDown, unbindKeyDown] = useEventListener({
        type: 'keydown',
        listener: (e) => {
            onKeyDown(e);
        }
    });

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if (!props.protocolId) return;

        loadProtocol();
        if(allowAction(['ProtocolsGetImage', 'ProtocolsGetImagesDto'])) loadProtocolThumbs();

    }, [props.protocolId])

    useEffect(() => {
        if (selectedPhotoIndex === null || selectedPhotoIndex === undefined || !protocolThumbs) return;
        const currentlySelectedImage = protocolThumbs[selectedPhotoIndex];
        if(!currentlySelectedImage) return;
        if (imageBlobMap && imageBlobMap[currentlySelectedImage.protocolImageId]) {
            const url = URL.createObjectURL(imageBlobMap[currentlySelectedImage.protocolImageId]);
            setImageSrc(url);
        } else {
            protocolService.downloadProtocolPhoto(currentlySelectedImage.protocolImageId)
                .then(resp => {
                    const blob = new Blob([resp], {type: "image.png"});
                    const url = URL.createObjectURL(blob);
                    setImageBlobMap({...imageBlobMap, [currentlySelectedImage.protocolImageId]: blob});
                    setImageSrc(url);
                }).catch()
        }

        if (!showImageDialog) {
            setShowImageDialog(true)
        }
    }, [selectedPhotoIndex])

    const onKeyDown = (e: any) => {
        if (e.key === 'ArrowRight') {
            goToNextPhoto()
            return;
        } else if (e.key === "ArrowLeft") {
            goToPrevPhoto();
            return;
        }
    };

    const loadProtocol = () => {
        protocolService.getProtocolById(props.protocolId)
            .then(resp => {

                vehicleService.getVehicleById(resp.vehicleId)
                    .then(resp => {
                        setVehicleForProtocol(resp)
                    });

                const tempSummaryProtocol: IProtocolSummary = {
                    dateTime: moment(resp.dateTime).format("DD-MM-YYYY HH:mm:ss"),
                    clientFirmName: resp.clientFirmName,
                    vehicleLpn: resp.vehicleLpn,
                    oldDeviceImei: resp.oldDeviceImei.toString(),
                    newDeviceId: resp.newDeviceId,
                    newDeviceImei: resp.newDeviceImei,
                    acceptName: resp.acceptName,
                    employees: resp.employees.map(el => el.employeeName),
                    checkEmployee: resp.checkEmployee?.employeeName,
                    protocolType: UtilService.protocolTypeOptions.find(el => el.id === resp.protocolType)?.description ?? "",
                    opinion: resp.opinion,
                    checked: resp.checked
                };

                setProtocolSummary(tempSummaryProtocol);
            })
    }

    const loadProtocolThumbs = () => {
        protocolService.getProtocolThumbnails(props.protocolId)
            .then(resp => {
                setProtocolThumbs(resp);
            })
    }

    const handleDownload = (thumbPhoto: IProtocolThumbResponse) => {
        if (imageBlobMap && imageBlobMap[thumbPhoto.protocolImageId]) {
            saveAs(imageBlobMap[thumbPhoto.protocolImageId], thumbPhoto.imageName);
        } else {
            protocolService.downloadProtocolPhoto(thumbPhoto.protocolImageId)
                .then(resp => {
                    const blob = new Blob([resp], {type: "image.png"});
                    saveAs(blob, thumbPhoto.imageName);
                }).catch()
        }

    }

    const uploadHandler = (event: FileUploadHandlerEvent) => {
        protocolService.uploadFileForProtocol(props.protocolId, event.files[0])
            .then(resp => {
                if (resp) {
                    showToast("success", f({id: "done"}), f({id: "successfullyUploadedFileToProtocol"}));
                    loadProtocolThumbs();
                    fileUploadRed.current.clear();
                }
            }).catch()
    }

    const confirmProtocol = () => {
        showConfirmDialog({body: f({id: "confirmMarkProtocolAsChecked"}, {vehicleLpn: protocolSummary!.vehicleLpn})}).then(answer => {
            if (answer) {
                protocolService.markProtocolAsChecked(props.protocolId).then(() => {
                    loadProtocol();
                    showToast("success", f({id: "done"}), f({id: "protocolMarkedAsChecked"}));
                })
            }
        })
    }

    const getAccountNameForGpsReports = async () => {
        //If the selectedVehicle has no gpsAccounts - do nothing
        if (!vehicleForProtocol || vehicleForProtocol.gpsAccounts.length === 0) return;

        let gpsAccountName: string | null = null;

        //If the selectedVehicle has exactly 1 gpsAccount - generate the report using this account
        if (vehicleForProtocol.gpsAccounts.length === 1) {
            gpsAccountName = vehicleForProtocol.gpsAccounts[0].item2;
        }
        //If the selected vehicle has more than 1 accounts - get all accounts for this gpsFirm and find the master account
        else if (vehicleForProtocol.gpsAccounts.length > 1) {
            const clientFirm = await clientFirmService.getClientFirmById(vehicleForProtocol.clientFirmId)
            if (!clientFirm) return;

            const accounts = await gpsProgramService.getAllGPSFirmsAccounts(clientFirm.gpsFirmName)

            if (accounts.length < 1) return;
            const masterAccount = accounts.find(el => el.master_account && vehicleForProtocol.gpsAccounts.map(el => el.item2).includes(el.userName));
            if (masterAccount) {
                gpsAccountName = masterAccount.userName;
            } else {
                gpsAccountName = accounts[0].userName;
            }

        }

        return gpsAccountName;
    }

    const prepareDailyRoadList = async () => {
        if (!vehicleForProtocol) return;
        const gpsAccountName = await getAccountNameForGpsReports();
        gpsProgramService.getDailyRoadList(gpsAccountName || "", vehicleForProtocol);
    }

    const prepareMonthlyRoadList = async () => {
        if (!vehicleForProtocol) return;
        const gpsAccountName = await getAccountNameForGpsReports();
        gpsProgramService.getMonthlyRoadList(gpsAccountName || "", vehicleForProtocol);

    }

    const showRawData = async () => {
        if (!vehicleForProtocol) return;
        let table = "";
        if(protocolSummary?.checked) {
            table = vehicleForProtocol.vehicleTable;
        }else {
            const device = await deviceService.getDeviceById(protocolSummary?.newDeviceId ?? 0);
            table = device.activeTable;
        }
        hideDialog();
        addWindow("rawData");
        history.push({
            pathname: '/rawData',
            state: {table: table}
        });
    }

    const deleteProtocolFile = (fileId: number) => {
        showConfirmDialog({body: f({id: "confirmDeleteProtocolFile"})}).then(answer => {
            if (answer) {
                protocolService.deleteProtocolFile(fileId).then(() => {
                    loadProtocolThumbs();
                    showToast("success", f({id: "done"}), f({id: "protocolFileDeletedSuccessfully"}));
                })
            }
        })
    }

    const handleImageDoubleClick = (photoIndex: number) => {
        bindKeyDown();
        setSelectedPhotoIndex(photoIndex);
        selectedPhotoIndexRef.current = photoIndex;
    }

    const goToPrevPhoto = () => {
        if (!protocolThumbs || protocolThumbs.length === 0 || selectedPhotoIndexRef.current === null || selectedPhotoIndexRef.current === undefined) return;
        const newIndexValue = selectedPhotoIndexRef.current === 0 ? protocolThumbs?.length - 1 : selectedPhotoIndexRef.current - 1;
        setSelectedPhotoIndex(newIndexValue);
        selectedPhotoIndexRef.current = newIndexValue;
    }

    const goToNextPhoto = () => {
        if (!protocolThumbs || protocolThumbs.length === 0 || selectedPhotoIndexRef.current === null || selectedPhotoIndexRef.current === undefined) return;
        const newIndexValue = selectedPhotoIndexRef.current === protocolThumbs.length - 1 ? 0 : selectedPhotoIndexRef.current + 1;
        setSelectedPhotoIndex(newIndexValue);
        selectedPhotoIndexRef.current = newIndexValue;
    }

    const handleDownloadProtocol = () => {
        protocolService.downloadProtocol(props.protocolId)
            .then(data => {

                const blob = UtilService.arrayBufferToBlob(data as ArrayBuffer);
                const url = URL.createObjectURL(blob);

                setPdfUrl(url);
                setShowPdfDialog(true);
            })
            .catch()
    }

    return <>
        {protocolSummary &&
            <div className={"flex grid"}>
                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "forFirm"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.clientFirmName}</label>
                </div>

                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "onDate"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.dateTime}</label>
                </div>

                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "type"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.protocolType}</label>
                </div>

                <Divider/>

                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "forVehicle"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.vehicleLpn}</label>
                </div>


                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "oldDeviceImei"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.oldDeviceImei === "0" ? f({id: "noDevice"}) : protocolSummary.oldDeviceImei}</label>
                </div>

                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "newDeviceImei"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.newDeviceImei || f({id: "noDevice"})}</label>
                </div>

                <Divider/>

                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "employees"})}: </label>
                    <label
                        className={"text-xl"}>{protocolSummary.employees.join(", ") || f({id: "noEmployees"})}</label>
                </div>

                <div className={"col-12 md:col-4"}>
                    <label className={"font-bold text-xl"}>{f({id: "checked"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.checked ? f({id: "yes"}) : f({id: "no"})}</label>
                </div>


                {protocolSummary.checked &&
                    <div className={"col-12 md:col-4"}>
                        <label className={"font-bold text-xl"}>{f({id: "checkedBy"})}: </label>
                        <label className={"text-xl"}>{protocolSummary.checkEmployee}</label>
                    </div>
                }

                <Divider/>

                <div className={"col-12"}>
                    <label className={"font-bold text-xl"}>{f({id: "note"})}: </label>
                    <label className={"text-xl"}>{protocolSummary.opinion || f({id: "noNote"})}</label>
                </div>

                <Fieldset legend={f({id: "operationsLabel"})} className={"col-12 mt-6"}
                          pt={{content: {className: "w-full grid"}}}>
                    <div className={"col-12 md:col-3"}>
                        <Button className={"w-full"}
                                label={f({id: "download"})}
                                onClick={handleDownloadProtocol}
                                icon={"pi pi-download"} iconPos={"right"}/>
                    </div>
                    <div className={"col-12 md:col-3"}>
                        <Button className={"w-full"}
                                label={protocolSummary.checked ? f({id: "alreadyChecked"}) : f({id: "markAsChecked"})}
                                onClick={confirmProtocol} disabled={protocolSummary.checked}
                                icon={"pi pi-check"} iconPos={"right"}/>
                    </div>

                    <div className={"col-12 md:col-3"}>
                        <Button className={"w-full"} label={f({id: "monthlyRoadList"})} onClick={prepareMonthlyRoadList}
                                disabled={!vehicleForProtocol || !vehicleForProtocol.gpsAccounts || vehicleForProtocol.gpsAccounts.length === 0}
                                icon={"fal fa-calendar-days"} iconPos={"right"}/>
                    </div>

                    <div className={"col-12 md:col-3"}>
                        <Button className={"w-full"} label={f({id: "dailyRoadList"})} onClick={prepareDailyRoadList}
                                disabled={!vehicleForProtocol || !vehicleForProtocol.gpsAccounts || vehicleForProtocol.gpsAccounts.length === 0}
                                icon={"fal fa-calendar-day"} iconPos={"right"}/>
                    </div>

                    <div className={"col-12 md:col-3"}>
                        <Button className={"w-full"} label={f({id: "rawData"})} onClick={showRawData}
                                icon={"pi pi-database"} iconPos={"right"}/>
                    </div>

                </Fieldset>
            </div>
        }


        <Divider>
            <div className={"flex flex-row justify-content-center align-items-center gap-3"}>
                <h2>{f({id: "photos"})}</h2>
                <FileUpload mode="basic" name="demo[]" accept="image/*" customUpload
                            ref={fileUploadRed}
                            uploadHandler={uploadHandler} auto chooseLabel={f({id: "uploadImage"})}/>
            </div>
        </Divider>
        {protocolThumbs && protocolThumbs.length > 0 &&
            <div className={"grid flex"}>
                {protocolThumbs?.map((el, index) => {
                    return <div className={"col-12 md:col-3"} style={{height: "20vh"}}>
                        <Image width={"100%"} height={"80%"}
                               onDoubleClick={(event) => handleImageDoubleClick(index)}
                               src={`data:image/png;base64, ${el.thumbnailBase64}`}
                               alt={"loading"}/>
                        <div className={"flex"}>
                            <DownloadButton onClick={() => handleDownload(el)}>
                                <label className={"cursor-pointer"}>{f({id: "download"})}</label>
                                <i className={"fal fa-download ml-auto"}/>
                            </DownloadButton>

                            <DeleteButton onClick={() => deleteProtocolFile(el.protocolImageId)} disabled={!allowAction(['ProtocolsImageDelete'])}>
                                <i className={"fal fa-trash"}/>
                            </DeleteButton>
                        </div>
                    </div>
                })}
            </div>
        }

        <Dialog visible={showImageDialog} onHide={() => {
            setShowImageDialog(false);
            setSelectedPhotoIndex(undefined);
            selectedPhotoIndexRef.current = undefined;
            unbindKeyDown();
        }} style={{maxWidth: "80vw"}}>
            <div className={"flex flex-row align-items-center"} >
                <i className={"pi pi-arrow-left mr-6 cursor-pointer"} onClick={goToPrevPhoto}/>
                {imageSrc ? <img src={imageSrc} alt="Fetched from backend" style={{maxWidth: "70vw", maxHeight: "80vh"}}/> : <p>Loading...</p>}
                <i className={"pi pi-arrow-right ml-6 cursor-pointer"} onClick={goToNextPhoto}/>
            </div>
        </Dialog>

        <Dialog visible={showPdfDialog} onHide={() => {
            setShowPdfDialog(false);
            setPdfUrl(undefined);
        }} style={{maxWidth: "80vw", minWidth: "55%", minHeight: "95%"}} resizable maximizable={true} blockScroll>
            <PdfPreview pdfString={pdfUrl || ""} />
        </Dialog>

    </>
};

export default PreviewProtocol
