import axios from "axios";
import {
    ICreateCreditOrDebitNote,
    ICreateInvoice,
    ICreateInvoiceGroup,
    IInvoice,
    IInvoiceTable,
    INote,
    InvoiceGroup
} from "../lib/types/types";
import moment from "moment";

export class InvoiceService {
    service = 'invoices/';

    async getInvoices(orderById?: boolean) {
        return new Promise<IInvoiceTable[]>((resolve, reject) => {
            axios.get<IInvoice[]>(this.service)
                .then((response) => {
                    const result: IInvoiceTable[] = response.data.map(({
                        invoiceId,
                        invoiceNumber,
                        invoiceDate,
                        invoiceStartPeriod,
                        invoiceEndPeriod,
                        totalCost,
                        dueDate,
                        createdBy,
                        paid,
                        clientFirm,
                        providerName,
                        recieverName,
                        invoiceType
                    }) => {
                        return {
                            invoiceId,
                            invoiceNumber,
                            invoiceDate,
                            invoiceStartPeriod,
                            invoiceEndPeriod,
                            totalCost,
                            dueDate,
                            createdBy,
                            paid,
                            invoiceType,
                            //@ts-ignore
                            clientFirm: clientFirm.clientFirmName,
                            provider: providerName,
                            receiver: recieverName
                        }
                    }
                    )
                    if(orderById){
                        resolve(result.sort((a,b) => b.invoiceId - a.invoiceId))
                    }else{
                        resolve(result);
                    }
                })
                .catch(reject)
        })
    }

    async getInvoicesForPeriod(startDateTime: string, endDateTime: string, orderById?: boolean) {
        return new Promise<IInvoiceTable[]>((resolve, reject) => {
            const data = { startDateTime, endDateTime };
            axios.post<IInvoice[]>(this.service + "period", data)
                .then(response => {
                    const result: IInvoiceTable[] = response.data.map(({
                        invoiceId,
                        invoiceNumber,
                        invoiceDate,
                        invoiceStartPeriod,
                        invoiceEndPeriod,
                        totalCost,
                        dueDate,
                        createdBy,
                        paid,
                        clientFirm,
                        providerName,
                        recieverName,
                        invoiceType
                    }) => {
                        return {
                            invoiceId,
                            invoiceNumber,
                            invoiceDate,
                            invoiceStartPeriod,
                            invoiceEndPeriod,
                            totalCost,
                            dueDate,
                            createdBy,
                            paid,
                            invoiceType,
                            //@ts-ignore
                            clientFirm: clientFirm.clientFirmName,
                            provider: providerName,
                            receiver: recieverName
                        }
                    }
                    )
                    if(orderById){
                        resolve(result.sort((a,b) => b.invoiceId - a.invoiceId))
                    }else{
                        resolve(result);
                    }
                })
                .catch(reject);
        })
    }

    async pay(payStatus: boolean, invoiceid: number) {
        return new Promise<boolean>((resolve, reject) => {
            const body = { invoiceid, payStatus }
            axios.post(this.service + "paystatus", body)
                .then(response => {
                    resolve(true);
                })
                .catch(reject)
        })
    }

    async deleteInvoice(invoiceId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete<boolean>(this.service + invoiceId)
                .then(response => {
                    if (response) resolve(true)
                    else reject(false)
                })
                .catch(reject);
        })
    }

    async createInvoice({clientFirmId, startDate} : ICreateInvoice) {
        return new Promise<boolean>((resolve, reject) => {
            const date = moment(startDate);
            date.add(1, "seconds");
            // date.ad
            const data = { clientFrimId: clientFirmId , startDate: date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") };
            axios.post<boolean>(this.service + 'generatetest', data)
                .then(response => {
                    if (response) resolve(true)
                    else reject(false)
                })
                .catch(reject);
        })
    }

    async downloadInvoice(invoiceId: number) {
        return new Promise((resolve, reject) => {
            axios.get(this.service + "download/" + invoiceId, {
                responseType: 'arraybuffer'})
            .then(response => resolve(response.data))
            .catch(reject);
        })
    }

    async getClientFirmInvoices(firmId: number, orderById?: boolean) {
        return new Promise<IInvoiceTable[]>((resolve, reject) => {
            axios.get<IInvoice[]>(this.service + 'clientfirm/' + firmId).then(resp => {
                const result: IInvoiceTable[] = resp.data.map(({
                    invoiceId,
                    invoiceNumber,
                    invoiceDate,
                    invoiceStartPeriod,
                    invoiceEndPeriod,
                    totalCost,
                    dueDate,
                    createdBy,
                    paid,
                    clientFirm,
                    providerName,
                    recieverName,
                    invoiceType
                }) => {
                    return {
                        invoiceId,
                        invoiceNumber,
                        invoiceDate,
                        invoiceStartPeriod,
                        invoiceEndPeriod,
                        totalCost,
                        dueDate,
                        createdBy,
                        paid,
                        invoiceType,
                        //@ts-ignore
                        clientFirm: clientFirm.clientFirmName,
                        provider: providerName,
                        receiver: recieverName,
                    }
                }
                )
                if(orderById){
                    resolve(result.sort((a,b) => b.invoiceId - a.invoiceId))
                }else{
                    resolve(result);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createInvoiceGroup(data: ICreateInvoiceGroup) {
        return new Promise<InvoiceGroup>((resolve, reject) => {
            axios.post<InvoiceGroup>(this.service + 'invoicegroups', {...data }).then(resp => {
                resolve(resp.data)
            }).catch(reject);
        })
    }

    async deleteInvoiceGroup(invoiceGroupId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete(this.service + 'invoicegroups/' + invoiceGroupId).then(resp => {
                resolve(true)
            }).catch(reject);
        })
    }

    async getInvoiceGroupsForVehicle(vehicleId: string) {
        return new Promise<InvoiceGroup[]>((resolve, reject) => {
            axios.get<InvoiceGroup[]>(this.service + "invoicegroups/vehicles/" + vehicleId)
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async getInvoiceGroupsForClientFirm(clientFirmId: number) {
        return new Promise<InvoiceGroup[]>((resolve, reject) => {
            axios.get<InvoiceGroup[]>(this.service + "invoicegroups/clientfirms/" + clientFirmId)
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async addVehiclesToInvoiceGroup(data: {invoiceGroupId: number, vehicleIds: number[]}) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(this.service + "invoicegroups/vehicles", data)
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async deleteVehiclesFromInvoiceGroup(data: {invoiceGroupId: number, vehicleIds: number[]}) {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete<boolean>(this.service + "invoicegroups/vehicles", {data})
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async getInvoiceNotes(invoiceId: number) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.get<INote[]>(this.service + invoiceId + "/invoicenote")
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async addInvoiceNotes(invoiceId: number, note: string) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.post<INote[]>(this.service + invoiceId + "/invoicenote", {note})
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async markInvoiceGroupToIncludeInvoiceNumbers(invoiceGroupId: number) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.post<INote[]>(this.service + "invoiceGroups/markIncludeInvoiceNumbers/" + invoiceGroupId)
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async createCreditNote(data: ICreateCreditOrDebitNote, invoiceId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(this.service + "creditNote", {...data, invoiceId})
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async createDebitNote(data: ICreateCreditOrDebitNote, invoiceId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(this.service + "debitNote", {...data, invoiceId})
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }
}
